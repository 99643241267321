export const environment = {
  production: false,
  apiUrl: 'https://dev.api.detroitharps.com',
  auth0: {
    clientID: 'WCmWyHK0ds6prgFZ3G2NphFwB2nnGTNe',
    domain: 'angband.auth0.com',
    audience: 'https://dev.api.detroitharps.com',
    redirectUri: 'https://dev.detroitharps.com/admin/callback'
  }
};
